
window.onload = function() {
  const spinner = document.getElementById('loading');
  const mainVisual = document.getElementById('mv');

  // ローディングスピナーを非表示にする
  spinner.classList.add('loaded');

  // スピナーが完全に消えるまで待ってからアニメーション開始
  setTimeout(() => {
    mainVisual.classList.add('is-mvshow');
  }, 1000); // CSSの `transition` に合わせて調整
}
