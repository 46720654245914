/* マーカー */
const markerText = document.querySelectorAll('.anime-marker'); // 監視対象を選択
const markerTextArr = Array.prototype.slice.call(markerText); // 監視対象をArrayに変換（IE対策）

/* スクロールアニメーション */
const scrollanime = document.getElementsByClassName('is-anime')
let scrollY;

function animationShow() {
  for (let i = 0; i < scrollanime.length; i++) {
    if (scrollanime[i].getAttribute('class').match(/is-show/) === null) {
      scrollY = window.pageYOffset + window.innerHeight
      clientTop = scrollanime[i].getBoundingClientRect().top + window.pageYOffset

      if (clientTop < scrollY) {
        scrollanime[i].setAttribute('class', scrollanime[i].getAttribute('class') + ' is-show')
      }
    }
  }
}

// 初期で読み込み
animationShow()

//スクロールで要素読み込み
window.addEventListener('scroll', () => {
  animationShow()
})
