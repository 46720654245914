
    const nav = document.getElementById('navMenu');
    const ham = document.getElementById('navBtn');
    const navlist = document.getElementById('navList');

    ham.addEventListener('click', function () {
        nav.classList.toggle('nav-open');
        ham.classList.toggle('nav-open');
    });
    navlist.addEventListener('click', function () {
        nav.classList.remove('nav-open');
        ham.classList.remove('nav-open');
    });
