window.addEventListener('scroll', function () {
  const totop = document.querySelector(".totop");
  const bodyHeight = document.body.clientHeight;
    // ↓document.documentElement.clientHeight +以降の記述はスクロールの高さを取得するための記述であるが、クローム、IEなどの各ブラウザに対応するために記述を分けている
  const windowScrollHeight = document.documentElement.clientHeight + document.documentElement.scrollTop || document.body.scrollTop || document.scrollingElement.scrollTop || window.pageYOffset || window.scrollY;
  const footerHeight = document.querySelector('.footer').clientHeight;
  if (bodyHeight - windowScrollHeight <= footerHeight) {
      totop.style.position = 'absolute';
      totop.style.bottom = footerHeight + 'px';
      totop.style.opacity = '1';
  } else {
      totop.style.position = 'fixed';
      totop.style.bottom = '10px';
      totop.style.opacity = '1';
  }
});

const pagetopBtn = document.querySelector('.totop');
pagetopBtn.addEventListener('click', () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
});
